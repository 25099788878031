<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    :class="bgColorClass"
  >
    <v-row
      v-if="!diaries.length"
    >
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            投稿がありません。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters
      class="mt-5 mb-10 px-4"
    >
      <v-col cols="6" sm="4" md="3" xl="2"
        v-for="diary in diaries"
        :key="diary.diary_id"
      >
        <v-card class="ma-2 pa-2"
          color="secondary"
          :to="{
            name: 'profile',
            params: {
              castId: diary.cast_id,
              castData: diary
            }
          }"
        >
          <!-- 画像 -->
          <v-img
            :src="diary.image_url"
            :lazy-src="lazyload_img"
            max-width="500px"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="accent"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <!-- 文章 -->
          <v-card-text class="px-2">
            <p class="diary-text ma-0 font-weight-bold"
              v-html="insertBrTag(diary.text)"
            ></p>
          </v-card-text>
          <v-card-subtitle class="pa-0 accent--text" align="right">
            {{ diaryFooter(diary) }}
          </v-card-subtitle>
        </v-card>
      </v-col>

      <!-- もっとボタン -->
      <v-col cols="12" class="mt-3">
        <v-btn
          :disabled="disableLoadMore"
          absolute right
          color="accent"
          @click="loadMoreDiary"
        >もっと見る
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************** Script *************** -->
<!-- ************************************* -->
<script>
import moment from 'moment'
import { LAYZLOAD_IMG } from '@/literals.js';
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    menus: {
      type: Array,
      required: true,
    },
  },

  //***************************************************
  // Data
  //***************************************************
  data() {
    return {
      diaries: [],
      queryLimit: 8,
      queryOffset: 0,
      disableLoadMore: false,
      lazyload_img: LAYZLOAD_IMG,
      apiTool: new ApiTool(this.siteApi, this.siteData),
    }
  },

  //***************************************************
  // Computed
  //***************************************************
  computed: {
    insertBrTag() {
      return str => str.replace(/\r?\n/g, '<br>')
    },
    bgColorClass() {
      if (this.setting.site_bg_image_url) {
        return ''
      }
      else if (this.setting.is_dark_theme) {
        return 'secondary darken-1'
      }
      else {
        return 'secondary lighten-1'
      }
    },
    jpDate() {
      return date =>  moment(date).format('M/D HH:mm')
    },
    diaryFooter() {
      return diary => diary.cast_name + ' ' + this.jpDate(diary.created_at)
    }
  },

  //***************************************************
  // Life cycle
  //***************************************************
  created() {
    this.getDiaries()
    .catch( error => { AlertApiError(error) })
  },

  //***************************************************
  // Methods
  //***************************************************
  methods: {
    async getDiaries() {
      await this.setDiaries()
      await this.setCasts()
    },

    // API req: 日記データ
    setDiaries() {
      const query = {limit: this.queryLimit, offset: this.queryOffset}

      return this.apiTool.getRequest('common/diary/', query).then( results => {
        if (!results || !results.length) {
          this.disableLoadMore = true
          return
        }

        results.map( diary => {
          //リアクティブ保持
          diary.cast_name = ''
          this.diaries.push({...diary})
        })

        this.queryOffset = this.queryOffset + this.queryLimit
      })
    },

    // API req: キャストデータ
    setCasts() {
      return this.apiTool.getRequest('cast/').then( results => {
        if (!results || !results.length) return

        this.diaries.map( diary => {
          results.some( cast => {
            if (diary.cast_id === cast.cast_id) {
              diary.cast_name = cast.name
              return true
            }
          })
        })
      })
    },

    // もっと読み込む
    loadMoreDiary() {
      this.getDiaries()
      .catch(error => { AlertApiError(error) })
    }
  }
}
</script>

<style scoped>
.diary-text {
  overflow-y: scroll;
  max-height: 300px;
  line-height: 1.5em;
}
.diary-text::-webkit-scrollbar {
  display: none;
}
.diary-text {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
